.container {
  background-image: url("../../../src/assets/images/background.jpg");
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form {
  background-color: rgb(255 255 255 / 60%);
  width: 300px;
  min-height: 300px;
  border-radius: 5px;
  box-shadow: 0 10px 20px 10px rgb(51 51 51 / 10%);
}

.logo {
  width: 64px;
  height: 64px;
}

.forgot-password {
  margin-top: -20px !important;
  margin-bottom: 20px !important;
  z-index: 1;
}
