.item-text {
  margin-left: 12px;
}

.expandable {
  margin-left: 30px;
  transition: max-height 0.5s ease;
  max-height: 0;
  overflow: hidden;
}

.expandable.open {
  max-height: 300px;
}
