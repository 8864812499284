:root {
  --margin-xs: 0.25rem;
  --margin-s: 0.5rem;
  --margin-m: 1rem;
  --margin-l: 1.5rem;
  --margin-xl: 2rem;
}

/* Margins */
.m-0 {
  margin: 0;
}

.m-xs {
  margin: var(--margin-xs);
}

.m-s {
  margin: var(--margin-s);
}

.m-m {
  margin: var(--margin-m);
}

.m-l {
  margin: var(--margin-l);
}

.m-xl {
  margin: var(--margin-xl);
}

/* Margins top */
.mt-xs {
  margin-top: var(--margin-xs);
}

.mt-s {
  margin-top: var(--margin-s);
}

.mt-m {
  margin-top: var(--margin-m);
}

.mt-l {
  margin-top: var(--margin-l);
}

.mt-xl {
  margin-top: var(--margin-xl);
}

/* Margins bottom */
.mb-xs {
  margin-bottom: var(--margin-xs);
}

.mb-s {
  margin-bottom: var(--margin-s);
}

.mb-m {
  margin-bottom: var(--margin-m);
}

.mb-l {
  margin-bottom: var(--margin-l);
}

.mb-xl {
  margin-bottom: var(--margin-xl);
}

/* Margins left */
.ml-xs {
  margin-left: var(--margin-xs);
}

.ml-s {
  margin-left: var(--margin-s);
}

.ml-m {
  margin-left: var(--margin-m);
}

.ml-l {
  margin-left: var(--margin-l);
}

.ml-xl {
  margin-left: var(--margin-xl);
}

/* Margins right */
.mr-xs {
  margin-right: var(--margin-xs);
}

.mr-s {
  margin-right: var(--margin-s);
}

.mr-m {
  margin-right: var(--margin-m);
}

.mr-l {
  margin-right: var(--margin-l);
}

.mr-xl {
  margin-right: var(--margin-xl);
}

/* Margins horizontal */
.mh-xs {
  margin-right: var(--margin-xs);
  margin-left: var(--margin-xs);
}

.mh-s {
  margin-right: var(--margin-s);
  margin-left: var(--margin-s);
}

.mh-m {
  margin-right: var(--margin-m);
  margin-left: var(--margin-m);
}

.mh-l {
  margin-right: var(--margin-l);
  margin-left: var(--margin-l);
}

.mh-xl {
  margin-right: var(--margin-xl);
  margin-left: var(--margin-xl);
}

/* Margins vertical */
.mv-xs {
  margin-top: var(--margin-xs);
  margin-bottom: var(--margin-xs);
}

.mv-s {
  margin-top: var(--margin-s);
  margin-bottom: var(--margin-s);
}

.mv-m {
  margin-top: var(--margin-m);
  margin-bottom: var(--margin-m);
}

.mv-l {
  margin-top: var(--margin-l);
  margin-bottom: var(--margin-l);
}

.mv-xl {
  margin-top: var(--margin-xl);
  margin-bottom: var(--margin-xl);
}

/* Paddings */
.p-xs {
  padding: var(--margin-xs);
}

.p-s {
  padding: var(--margin-s);
}

.p-m {
  padding: var(--margin-m);
}

.p-l {
  padding: var(--margin-l);
}

.p-xl {
  padding: var(--margin-xl);
}

/* Paddings top */
.pt-xs {
  padding-top: var(--margin-xs);
}

.pt-s {
  padding-top: var(--margin-s);
}

.pt-m {
  padding-top: var(--margin-m);
}

.pt-l {
  padding-top: var(--margin-l);
}

.pt-xl {
  padding-top: var(--margin-xl);
}

/* Paddings bottom */
.pb-xs {
  padding-bottom: var(--margin-xs);
}

.pb-s {
  padding-bottom: var(--margin-s);
}

.pb-m {
  padding-bottom: var(--margin-m);
}

.pb-l {
  padding-bottom: var(--margin-l);
}

.pb-xl {
  padding-bottom: var(--margin-xl);
}

/* Paddings left */
.pl-xs {
  padding-left: var(--margin-xs);
}

.pl-s {
  padding-left: var(--margin-s);
}

.pl-m {
  padding-left: var(--margin-m);
}

.pl-l {
  padding-left: var(--margin-l);
}

.pl-xl {
  padding-left: var(--margin-xl);
}

/* Paddings right */
.pr-xs {
  padding-right: var(--margin-xs);
}

.pr-s {
  padding-right: var(--margin-s);
}

.pr-m {
  padding-right: var(--margin-m);
}

.pr-l {
  padding-right: var(--margin-l);
}

.pr-xl {
  padding-right: var(--margin-xl);
}

/* Paddings horizontal */
.ph-xs {
  padding-right: var(--margin-xs);
  padding-left: var(--margin-xs);
}

.ph-s {
  padding-right: var(--margin-s);
  padding-left: var(--margin-s);
}

.ph-m {
  padding-right: var(--margin-m);
  padding-left: var(--margin-m);
}

.ph-l {
  padding-right: var(--margin-l);
  padding-left: var(--margin-l);
}

.ph-xl {
  padding-right: var(--margin-xl);
  padding-left: var(--margin-xl);
}

/* Paddings vertical */
.pv-xs {
  padding-top: var(--margin-xs);
  padding-bottom: var(--margin-xs);
}

.pv-s {
  padding-top: var(--margin-s);
  padding-bottom: var(--margin-s);
}

.pv-m {
  padding-top: var(--margin-m);
  padding-bottom: var(--margin-m);
}

.pv-l {
  padding-top: var(--margin-l);
  padding-bottom: var(--margin-l);
}

.pv-xl {
  padding-top: var(--margin-xl);
  padding-bottom: var(--margin-xl);
}
