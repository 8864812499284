@font-face {
  font-family: National;
  src: url("../../assets/fonts/National-Black.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: National;
  src: url("../../assets/fonts/National-Bold.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: National;
  src: url("../../assets/fonts/National-Light.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: National;
  src: url("../../assets/fonts/National-LightItalic.otf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: National;
  src: url("../../assets/fonts/National-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: National;
  src: url("../../assets/fonts/National-RegularItalic.otf");
  font-weight: 400;
  font-style: italic;
}
