.flex {
  display: flex;
}

/* flex-direction */
.column {
  flex-direction: column;
}

.column-reverse {
  flex-direction: column-reverse;
}

.row {
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;
}

/* align-items */
.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.align-stretch {
  align-items: stretch;
}

/* align-self */
.self-stretch {
  align-self: stretch;
}

.self-center {
  align-self: center;
}

/* justify-content */
.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

/* flex and flex-grow */
.fill {
  flex: 1;
}

.grow-1 {
  flex-grow: 1;
}

.scroll-space-around {
  flex-grow: 1;
  justify-content: space-around;
}

.scroll-space-between {
  flex-grow: 1;
  justify-content: space-between;
}

/* Size */
.full-size {
  height: 100%;
  width: 100%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

/* transform */
.mirror {
  transform: scaleX(-1);
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate90-inverse {
  transform: rotate(-90deg);
}

/* text-align */
.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* position */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

/* wrap */
.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}
